
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import Modal from "@/components/Modal.vue";
import LoanService, {
  SaveLoanPersonalData,
  SaveLoanResponse
} from "@/services/loan-service";
import { mask } from "vue-the-mask";
import municipios from "@/data/municipios";
import SaveButton from "@/components/SaveButton.vue";
import ConsignetDataSelection from "@/components/margin-bases/ConsignetDataSelection.vue";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import dayjs from "dayjs";
import { format as formatCNPJ } from "@/utils/cnpj";
import formatCurrency from "@/utils/formatCurrency";
import { checkIfLoanFlowCacheIsRecent } from "@/utils/checkIfLoanFlowCacheIsRecent";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";

interface State {
  form: any;
  cities: string[];
  saving: boolean;
}

interface Cache extends State {
  _cacheDate: Date;
}

@Component({
  components: { ValidationObserver, Modal, SaveButton, ConsignetDataSelection },
  directives: { mask }
})
export default class LoanPersonalData extends Vue {
  @Prop() readonly loan!: SaveLoanResponse;
  @Prop({ type: Object, default: () => ({}) }) cache!: Cache;
  /** Used to preserve state (i.e., all relevant data) during re-renders caused by reactive behavior */
  @Prop() readonly editState!: State;
  /** Used on component creation to avoid treating programmatic changes to form as if they were actual user input */
  isProgrammaticFormInput: boolean = false;
  loading: boolean = false;
  loanService: LoanService;
  states = municipios.estados;
  cities: string[] = [];
  saving: boolean = false;
  formatCurrency = formatCurrency;
  minimumBirthDate = dayjs()
    .startOf("day")
    .subtract(90, "years")
    .format("DD/MM/YYYY");
  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;
  formatCNPJ = formatCNPJ;

  emptyForm: SaveLoanPersonalData = {
    user: {
      name: "",
      email: "",
      phone: ""
    },
    borrower: {
      name: "",
      cpf: "",
      birthDate: "",
      zipCode: "",
      address: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
      phone: "",
      homePhone: "",
      rg: "",
      rgIssuingAgency: "",
      rgIssuedState: "",
      birthPlace: "",
      maritalStatus: "",
      motherName: ""
    },
    loan: {
      id: 0
    }
  };
  form: SaveLoanPersonalData = cloneDeep(this.emptyForm);

  maritalStatuses = [
    { value: "1", text: "Solteiro(a)" },
    { value: "2", text: "Casado(a)" },
    { value: "3", text: "Desquitado(a)" },
    { value: "4", text: "Divorciado(a)" },
    { value: "5", text: "Separado(a)" },
    { value: "6", text: "Viúvo(a)" },
    { value: "7", text: "Outros" },
    { value: "8", text: "Não informado" },
    { value: "9", text: "Vinculo conjugal" }
  ];

  constructor() {
    super();

    this.loanService = LoanService.getInstance();
  }

  @Watch("form", { deep: true })
  async formChanged(): Promise<void> {
    this.onUnsavedChanges();
  }

  async created(): Promise<void> {
    this.isProgrammaticFormInput = true;

    if (!isEmpty(this.editState)) {
      this.restoreDataFrom(this.editState);
    } else {
      if (this.isCacheValid()) {
        this.restoreDataFrom(this.cache);
      } else {
        await this.loadSimulation();
      }
    }

    this.$nextTick(() => {
      this.isProgrammaticFormInput = false;
    });
  }

  isCacheValid(): boolean {
    const isCacheRecent = checkIfLoanFlowCacheIsRecent(this.cache?._cacheDate);

    return (
      isCacheRecent &&
      !isEmpty(this.cache) &&
      !isEmpty(this.cache.form) &&
      !isEmpty(this.cache.cities)
    );
  }

  saveCache(): void {
    const data: Cache = {
      _cacheDate: new Date(),
      form: this.form,
      cities: this.cities,
      saving: this.saving
    };

    this.$emit("loadData", cloneDeep(data));
  }

  restoreDataFrom(data: State | Cache): void {
    this.form = cloneDeep(data.form);
    this.cities = cloneDeep(data.cities);
    this.saving = cloneDeep(data.saving);
  }

  onUnsavedChanges(): void {
    if (!this.isProgrammaticFormInput) {
      this.$emit("unsavedChanges");
    }
    const data: State = {
      form: this.form,
      cities: this.cities,
      saving: this.saving
    };
    this.$emit("updateEditState", data);
  }

  loadCities(): void {
    if (this.form.borrower.state) {
      this.cities = municipios.cidadePorEstado(this.form.borrower.state);
    } else {
      this.cities = [];
    }
    if (
      this.form.borrower.city &&
      !this.cities.includes(this.form.borrower.city)
    ) {
      this.form.borrower.city = "";
    }
  }

  async loadSimulation(): Promise<void> {
    this.loading = true;
    const [error, response] = await this.loanService.getSimulation(
      this.loan.borrower.cpf.replace(/\D/g, "")
    );

    if (error) {
      this.loading = false;
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(error),
        type: "error"
      });
      return;
    }

    if (response) {
      if (response.user) {
        this.form.user.name = response.user.name;
        this.form.user.email = response.user.email;
        this.form.user.phone = response.user.phone.replace("+55", "");
      }

      if (response.loan) {
        this.form.loan.id = response.loan.id;
      }

      if (response.borrower) {
        this.form.borrower.cpf = response.borrower.cpf;
        this.form.borrower.name = response.borrower.name;
        this.form.borrower.birthDate = dayjs(
          response.borrower.birthDate
        ).format("DD/MM/YYYY");
        this.form.borrower.zipCode = response.borrower.zipCode;
        this.form.borrower.address = response.borrower.address;
        this.form.borrower.number = response.borrower.number;
        this.form.borrower.complement = response.borrower.complement;
        this.form.borrower.district = response.borrower.district;
        this.form.borrower.city = response.borrower.city;
        this.form.borrower.state = response.borrower.state;
        this.form.borrower.homePhone = response.borrower.homePhone
          ? response.borrower.homePhone?.replace("+55", "")
          : "";
        this.form.borrower.phone = response.borrower.phone.replace("+55", "");
        this.form.borrower.rg = response.borrower.rg;
        this.form.borrower.rgIssuingAgency = response.borrower.rgIssuingAgency;
        this.form.borrower.rgIssuedState = response.borrower.rgIssuedState;
        this.form.borrower.birthPlace = response.borrower.birthPlace;
        this.form.borrower.maritalStatus = response.borrower.maritalStatus;
        this.form.borrower.motherName = response.borrower.motherName;
      }

      this.$emit("updatedLoan", response);
    }

    this.loading = false;
  }

  async save(): Promise<void> {
    this.saving = true;
    const data: SaveLoanPersonalData = cloneDeep(this.form);

    data.borrower.birthDate = dayjs(
      data.borrower.birthDate,
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");

    data.borrower.cpf = data.borrower.cpf.replace(/\D/g, "");
    data.borrower.phone = data.borrower.phone.replace(/\D/g, "");
    data.borrower.homePhone = data.borrower?.homePhone
      ? data.borrower.homePhone.replace(/\D/g, "")
      : "";

    const [error, response] = await this.loanService.saveLoanPersonalData(data);

    this.saving = false;
    if (error) {
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(error),
        type: "error"
      });
      return;
    }

    if (response) {
      this.$notify({
        title: "Sucesso",
        text: "Empréstimo salvo com sucesso.",
        type: "success"
      });

      this.saveCache();
      this.$emit("updatedLoan", response);
      this.goToNextStep();
    }
  }

  goToPreviousStep(): void {
    this.$emit("previous");
  }

  goToNextStep(): void {
    this.$emit("next");
  }
}
