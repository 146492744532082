
import Vue from "vue";
import Component from "vue-class-component";
import dayjs from "dayjs";
import LoanService from "@/services/loan-service";
import Loan from "@/types/loan";
import { Prop } from "vue-property-decorator";
import { ILoanStatusHistoryWithUsers } from "@/types/loan-status-history";
import SafetyService from "@/services/safety-service";

@Component
export default class LoanHistoryModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Loan;
  service: LoanService;
  loanHistory: ILoanStatusHistoryWithUsers[] = [];
  loading: boolean = true;
  headers = [
    { text: "Data Ocorrência", value: "date" },
    { text: "Evento", value: "status" },
    { text: "Tipo de Usuário", value: "user.type" },
    { text: "Usuário", value: "user.email" }
  ];
  safetyService: SafetyService;

  constructor() {
    super();
    this.service = LoanService.getInstance();
    this.safetyService = SafetyService.getInstance();
  }

  mounted() {
    this.fetchLoanHistory();
  }

  async fetchLoanHistory() {
    try {
      this.loading = true;
      const [loanHistoryError, loanHistory] =
        await this.service.getLoanHistoryByLoanId(this.item.id);

      if (loanHistoryError) {
        this.$notify({
          type: "error",
          text: "Não foi possível carregar o histórico do empréstimo."
        });
      } else {
        this.loanHistory = loanHistory || [];
      }
    } catch (error) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro inesperado ao carregar o histórico."
      });
    } finally {
      this.loading = false;
    }
  }

  formatDate(date: Date): string {
    return dayjs(date).format("DD/MM/YYYY");
  }
}
