export default interface Borrower {
  id: number;
  userId: number;
  signingMethod: BorrowerSigningMethod;
  clicksignSignerId?: string | null;
  currentSignupStep?: string | null;
  name?: string | null;
  cpf?: string | null;
  birthDate?: Date | null | string;
  phone?: string | null;
  zipCode?: string | null;
  address?: string | null;
  number?: string | null;
  complement?: string | null;
  district?: string | null;
  city?: string | null;
  state?: string | null;
  homePhone?: string | null;
  rg?: string | null;
  rgIssuingAgency?: string | null;
  rgIssuedState?: string | null;
  birthPlace?: string | null;
  maritalStatus?: string | null;
  motherName?: string | null;
  creditPolicyId?: number | null;
  creditPolicyStatus?: boolean;
  inicialCreditPolicyDueDate?: Date;
  finalCreditPolicyDueDate?: Date;
  borrowerNameAndCpf?: string | null;
}

export enum BorrowerSigningMethod {
  SMS = "sms",
  WHATSAPP = "whatsapp"
}
