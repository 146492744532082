
import AlertBox from "@/components/AlertBox.vue";
import LoanService from "@/services/loan-service";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import { format as formatDocument } from "@/utils/cpfOrCnpj";
import formatCurrency from "@/utils/formatCurrency";
import { sumBy } from "lodash";
import { ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { AllowedToRefinancingLoan } from "../../services/loan-service/types/allowed-to-refinancing-loan";
import Loan from "../../types/loan";
import Refinancing from "../../types/refinancing";
import debounce from "debounce";

@Component({
  components: { AlertBox, ValidationProvider }
})
export default class RefinancingLoansManage extends Vue {
  @Prop({ type: String, default: "" }) cpf!: string;
  @Prop() readonly amount!: number;
  @Prop() readonly loan!: Loan;
  @Prop() readonly allowedToRefinancingLoans!: Array<AllowedToRefinancingLoan>;
  selectedRefinancingLoans: AllowedToRefinancingLoan[];
  debounceRefinancingLoansChanged = debounce(
    this.selectedRefinancingLoansChanged,
    1500
  );

  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  formatDocument = formatDocument;
  formatCurrency = formatCurrency;
  loanService: LoanService;

  headers: Array<DataTableHeader>;
  loading: boolean;

  @Watch("selectedRefinancingLoans")
  onSelectedRefinancingLoansChange(): void {
    this.debounceRefinancingLoansChanged();
  }

  constructor() {
    super();
    this.loanService = LoanService.getInstance();

    this.headers = [
      { text: "Contrato", value: "id" },
      { text: "Valor Contrato", value: "total", width: "20%" },
      { text: "Saldo Devedor", value: "debtBalance", width: "20%" },
      { text: "Valor Parcela", value: "installmentValue", width: "20%" },
      { text: "Prazo", value: "numInstallments", width: "20%" },
      { text: "Parcelas Pendentes", value: "pendingInstallments", width: "20%" }
    ];

    const selectedLoansInstallmentsIds =
      this.loan?.refinancing?.installmentsRefinancings.map(
        (obj) => obj.loanInstallmentId
      );

    this.selectedRefinancingLoans = this.allowedToRefinancingLoans.filter(
      (refinancingLoan) =>
        this.isArrayContained(
          refinancingLoan.installmentsIds,
          selectedLoansInstallmentsIds || []
        )
    );

    this.$emit("input", this.refinancing);
    this.$emit("sum", this.sumOfRefinancings);
    this.loading = false;
  }

  startLoading(): void {
    this.loading = true;
  }

  selectedRefinancingLoansChanged(): void {
    this.$emit("input", this.refinancing);
    this.$emit("sum", this.sumOfRefinancings);
    this.$emit("shouldRunCreditEngine", true);
  }

  stopLoading(): void {
    this.loading = false;
  }

  isArrayContained(subArray: number[], mainArray: number[]): boolean {
    return subArray.every((item) => mainArray.includes(item));
  }

  get refinancing(): Refinancing {
    const refinancing: Refinancing = {
      id: this.loan?.refinancing?.id,
      installmentsRefinancings: []
    };

    for (const selectedRefinancingLoan of this.selectedRefinancingLoans) {
      const installmentsIdsOfSelectedRefinancingLoan =
        selectedRefinancingLoan.installmentsIds;

      for (const installmentId of installmentsIdsOfSelectedRefinancingLoan) {
        const preExistingRegisterForThisInstallment =
          this.loan?.refinancing?.installmentsRefinancings?.find(
            (ir) => ir.loanInstallmentId === installmentId
          );

        refinancing.installmentsRefinancings.push({
          id: preExistingRegisterForThisInstallment?.id,
          loanInstallmentId: installmentId
        });
      }
    }

    return refinancing;
  }

  get sumOfRefinancingsDontExceedAmount(): boolean {
    return this.sumOfRefinancings <= this.amount;
  }

  get sumOfRefinancings(): number {
    return sumBy(this.selectedRefinancingLoans, "debtBalance");
  }

  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }

  get isPartnerMaster(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "PARTNER_MASTER"
    );
  }

  get isPartnerEmployee(): boolean {
    return this.$store.getters["auth/authenticatedUser"]?.type == "PARTNER";
  }
}
