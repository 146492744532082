
import Loan from "@/types/loan";
import LoanType from "@/types/loan-type";
import { orderBy } from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import ListLoansParams from "@/services/loan-service/types/list-loans-params";
import CompanyService, {
  ListCompaniesResponse
} from "@/services/company-service";
import EletronicSignatureService from "@/services/eletronic-signature-service";
import BorrowerService from "@/services/borrower-service";
import LoanService from "@/services/loan-service";
import LoanStatus from "@/types/loan-status";
import LoanSimulationType from "@/types/loan-simulation-type";
import LoanStatusGroup from "@/types/loan-status-group";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import { format as formatDocument } from "@/utils/cpfOrCnpj";
import formatDate from "@/utils/formatDate";
import User from "@/types/user";
import DatePicker from "@/components/DatePicker.vue";
import formatDatetime from "../../utils/formatDatetime";
import LoanFlowModal from "@/components/loans/LoanFlowModal.vue";
import PartnerService from "@/services/partner-service";
import SafetyService from "@/services/safety-service";
import { exportToSpreadsheet } from "../../utils/exportToSpreadsheet";
import dayjs from "dayjs";
import { LoanStatusEnum } from "@/types/loan-status";
import { LoanTypeEnum } from "@/types/loan-type";

interface Filters {
  loanId: number | null;
  borrowerCpf?: string | null;
  proposalNumber: string | null;
  companyId?: number | null;
  statusId?: number | null;
  simulationTypeId?: number | null;
  statusGroupId?: number | null;
  financialInstitutionId?: number | null;
  typeId?: number | null;
  startedSimulationDateStart?: string | null;
  startedSimulationDateEnd?: string | null;
  requestDateStart?: string | null;
  requestDateEnd?: string | null;
  updatedAtDateStart?: string | null;
  updatedAtDateEnd?: string | null;
  paidDateStart?: string | null;
  paidDateEnd?: string | null;
}

@Component({
  components: {
    DatePicker,
    LoanFlowModal
  }
})
export default class LoansTable extends Vue {
  @Prop({ default: [] }) readonly loans!: {
    items: Array<Loan & { user: User }>;
    total: number;
  };
  @Prop() fetch!: (options: ListLoansParams) => Promise<void>;
  @Prop() refresh!: number;

  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  formatDocument = formatDocument;
  formatDate = formatDate;
  formatDatetime = formatDatetime;

  companyService: CompanyService;
  borrowerService: BorrowerService;
  partnerService: PartnerService;
  safetyService: SafetyService;
  loanService: LoanService;
  eletronicSignatureService: EletronicSignatureService;

  continueLoanCpf: string;

  options: DataOptions;
  headers: Array<DataTableHeader>;
  selectedHeaders: Array<DataTableHeader> = [];
  loading: boolean;
  filters: Filters = {
    loanId: null,
    borrowerCpf: "",
    proposalNumber: null,
    companyId: null,
    statusId: null,
    statusGroupId: null,
    simulationTypeId: null,
    financialInstitutionId: null,
    typeId: null,
    startedSimulationDateStart: null,
    startedSimulationDateEnd: null,
    requestDateStart: null,
    requestDateEnd: null,
    updatedAtDateStart: null,
    updatedAtDateEnd: null,
    paidDateStart: null,
    paidDateEnd: null
  };
  dateFilterTypes: Array<string> = [
    "Data de contratação",
    "Data de início da simulação",
    "Data de pagamento",
    "Data da última atualização"
  ];
  dateFilterType = "Data de contratação";
  companies: ListCompaniesResponse = {
    items: [],
    total: 0
  };
  borrowers: Array<{ id: number; name: string }> | null = null;
  statusList: LoanStatus[] | null = null;
  statusGroupList: LoanStatusGroup[] | null = null;
  simulationTypesList: LoanSimulationType[] | null = null;
  typesList: LoanType[] | null = null;
  LoanTypeEnum = LoanTypeEnum;
  statusThatAllowGoorooCancel: string[];
  fileManagerUrl: string;
  showGridOptions: boolean = false;
  LoanStatusEnum = LoanStatusEnum;

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.borrowerService = BorrowerService.getInstance();
    this.loanService = LoanService.getInstance();
    this.eletronicSignatureService = EletronicSignatureService.getInstance();
    this.partnerService = PartnerService.getInstance();
    this.safetyService = SafetyService.getInstance();

    this.continueLoanCpf = "";
    this.headers = [
      { text: "Proposta", value: "id" },
      { text: "Empresa", value: "companyName", width: "20%" },
      { text: "Tomador", value: "borrowerName", sortable: false, width: "20%" },
      { text: "Parceiro", value: "partner", sortable: false, width: "20%" },
      {
        text: "Tipo de simulação",
        value: "simulationType",
        sortable: false,
        width: "10%"
      },
      {
        text: "Tipo de empréstimo",
        value: "loanType",
        sortable: false,
        width: "10%"
      },
      {
        text: "Status da Contratação",
        value: "status",
        sortable: false,
        width: "10%"
      },
      {
        text: "Status do Contrato",
        value: "status.statusGroup",
        sortable: false,
        width: "10%"
      },
      {
        text: "Data de pagamento",
        value: "paidDate",
        sortable: true,
        width: "10%"
      },
      {
        text: "Última atualização",
        value: "updated_at",
        sortable: true,
        width: "10%"
      },
      {
        text: "Contrato",
        value: "proposalNumber",
        sortable: false,
        width: "10%"
      },
      { text: "Ações", value: "actions", sortable: false, width: "180px" }
    ];
    this.loading = false;
    this.options = {} as DataOptions;
    this.statusThatAllowGoorooCancel = [
      "PENDENTE_ASSINATURA",
      "PENDENTE_VALIDACAO_DOCUMENTOS",
      "PENDENTE_VALIDACAO_EXTERNA_DOCUMENTOS",
      "PENDENTE_PAGAMENTO"
    ];
    this.fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
  }

  mounted() {
    this.loadFilters();

    const notDefaultHeaders = ["partner"];
    this.selectedHeaders = this.headers.filter(
      (h) => !notDefaultHeaders.includes(h.value)
    );
  }

  @Watch("options")
  onOptionsChange(val: DataOptions) {
    this.fetchLoans(val);
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.startedSimulationDateStart = null;
    this.filters.startedSimulationDateEnd = null;
    this.filters.requestDateStart = null;
    this.filters.requestDateEnd = null;
    this.filters.updatedAtDateStart = null;
    this.filters.updatedAtDateEnd = null;
    this.filters.paidDateStart = null;
    this.filters.paidDateEnd = null;
  }

  get selectedHeadersList() {
    return this.headers.filter((h) => this.selectedHeaders.includes(h));
  }
  toggleHeaderSelection(item: DataTableHeader) {
    if (this.selectedHeaders.includes(item)) {
      this.selectedHeaders = this.selectedHeaders.filter(
        (selectedItem) => selectedItem !== item
      );
    } else {
      this.selectedHeaders.push(item);
    }
  }

  async loadFilters(): Promise<void> {
    const [companyError, companies] = await this.companyService.listCompanies({
      page: 1,
      limit: -1,
      sort: "name:ASC"
    });

    if (companyError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de empresas para o filtro."
      });
    }

    if (companies) {
      this.companies = companies;
    }

    const [statusListError, statusList] =
      await this.loanService.getStatusList();
    this.statusList = statusList;

    if (statusListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de status para o filtro."
      });
    }

    const [statusGroupListError, statusGroupList] =
      await this.loanService.getStatusGroupList();
    this.statusGroupList = statusGroupList;

    if (statusGroupListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de grupos de status para o filtro."
      });
    }

    const [simulationTypesListError, simulationTypesList] =
      await this.loanService.getSimulationTypesList();
    this.simulationTypesList = simulationTypesList;

    if (simulationTypesListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de grupos de status para o filtro."
      });
    }

    const [typesListError, typesList] = await this.loanService.getTypesList();
    this.typesList = typesList;

    if (typesListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de tipos de empréstimo para o filtro."
      });
    }
  }

  async fetchLoans(val: DataOptions) {
    const { page, itemsPerPage, sortBy, sortDesc } = val;
    const {
      loanId,
      borrowerCpf,
      proposalNumber,
      companyId,
      statusId,
      statusGroupId,
      simulationTypeId,
      financialInstitutionId,
      typeId,
      startedSimulationDateStart,
      startedSimulationDateEnd,
      requestDateStart,
      requestDateEnd,
      updatedAtDateStart,
      updatedAtDateEnd,
      paidDateStart,
      paidDateEnd
    } = this.filters;

    this.startLoading();
    await this.fetch({
      loanId,
      page: page,
      limit: itemsPerPage,
      sort: this.handleSort(sortBy, sortDesc),
      borrowerCpf,
      proposalNumber,
      companyId,
      statusId,
      simulationTypeId,
      statusGroupId,
      financialInstitutionId,
      typeId,
      startedSimulationDateStart,
      startedSimulationDateEnd,
      requestDateStart,
      requestDateEnd,
      updatedAtDateStart,
      updatedAtDateEnd,
      paidDateStart,
      paidDateEnd
    });
    this.stopLoading();
  }

  @Watch("refresh")
  onRefreshChange() {
    this.options = { ...this.options };
  }

  applyFilter() {
    this.options.page = 1;
    this.fetchLoans(this.options);
  }

  emitShow(item: Loan) {
    this.$emit("show", item);
  }

  approveLoan(item: Loan): void {
    this.$emit("approve", item);
  }

  refuseLoan(item: Loan): void {
    this.$emit("refuse", item);
  }

  updateLoanStatus(item: Loan): void {
    this.$emit("updateStatus", item);
  }

  showLoanHistory(item: Loan): void {
    this.$emit("showLoanHistory", item);
  }

  updateLoanCompany(item: Loan): void {
    this.$emit("updateCompany", item);
  }

  updateRefusedBankSlip(item: Loan): void {
    this.$emit("updateRefusedBankSlip", item);
  }

  undoFiredEmployeeLoan(item: Loan): void {
    this.$emit("undoFiredEmployee", item);
  }

  updateSigningMethod(item: Loan): void {
    this.$emit("updateSigningMethod", item);
  }

  handleSort(sortBy: Array<string>, sortDesc: Array<boolean>) {
    const attr = sortBy[0] ?? "id";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return attr ? `${attr}:${order}` : undefined;
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

  async downloadExcel(): Promise<void> {
    const sheet: (number | string)[][] = [
      [
        "Empresa",
        "CNPJ",
        "Colaborador",
        "CPF",
        "Email",
        "Telefone",
        "Numero_proposta",
        "Tipo_proposta",
        "Status",
        "Parcelas",
        "Valor_parcelas",
        "Data_simulacao",
        "Data_solicitacao",
        "Data_averbacao",
        "Valor_liquido_emprestimo",
        "Valor_iof",
        "Valor_Tarifa_Gooroo_Analisa",
        "Valor_seguro",
        "Valor_credito",
        "Valor_total",
        "Data_pagamento",
        "Data_ultima_atualizacao",
        "Contrato",
        "Data_pagamento_em_andamento",
        "Taxa de Juros Mensal ",
        "Parceiro",
        "Comercial Responsavel"
      ]
    ];

    if (this.isAdminGooroo) {
      sheet[0].push("Observação_Não_Averbação");
      sheet[0].push("Observação_Atualização_Status");
      sheet[0].push("Margem_Não_Averbação");
    }

    for (const item of this.loans.items) {
      const commercialResponsable = item.partnerId
        ? (await this.partnerService.getPartnerCommercial(item.partnerId))[1]
        : null;
      const userCommercial = commercialResponsable?.responsibleCommercialId
        ? (
            await this.safetyService.listUsers({
              userIds: [commercialResponsable.responsibleCommercialId],
              page: 1,
              limit: -1
            })
          )[1]
        : null;

      const sheetLine = [
        item.companyName || "Cadastrando",
        item.companyCnpj ? this.formatCNPJ(item.companyCnpj) : "Cadastrando",
        item.borrowerName || item.user?.name || "Cadastrando",
        item.borrowerCpf ? this.formatCPF(item.borrowerCpf) : "Cadastrando",
        item.user?.email || "",
        item.user?.phone || "",
        item.id?.toString() || "Cadastrando",
        item.type?.description.toString() || "Cadastrando",
        item.status?.description || "Cadastrando",
        item.numInstallments?.toString() || "Cadastrando",
        item.installmentValue ? item.installmentValue : 0,
        item.startedSimulationDate
          ? this.formatDatetime(item.startedSimulationDate)
          : "Cadastrando",
        item.requestDate
          ? this.formatDatetime(item.requestDate)
          : "Cadastrando",
        item.endorsementDate
          ? this.formatDatetime(item.endorsementDate)
          : "Cadastrando",
        item.requestedAmount ? item.requestedAmount : 0,
        item.iofValue ? item.iofValue : 0,
        item.tacValue ? item.tacValue : 0,
        item.insuranceValue ? item.insuranceValue : 0,
        item.creditAmount ? item.creditAmount : 0,
        item.total ? item.total : 0,
        item.paidDate ? formatDate(item.paidDate) : "-",
        item.updated_at ? formatDatetime(item.updated_at) : "-",
        item.proposalNumber ? item.proposalNumber : "-",
        item.paymentInProcessDate
          ? formatDatetime(item.paymentInProcessDate)
          : "-",
        item.monthlyFee ? item.monthlyFee.toString() : "-",
        item.partnerName ? item.partnerName : "Cadastrando",
        (await userCommercial)
          ? await userCommercial.items[0].name
          : "Cadastrando"
      ];

      if (this.isAdminGooroo) {
        sheetLine.push(
          item.endorsementObservation ||
            item.nonEndorsementMotive?.readableName ||
            "-"
        );
        sheetLine.push(this.getObservationOfCurrentLoanStatus(item));
        sheetLine.push(item.nonEndorsementAvailableMargin || "");
      }

      sheet.push(sheetLine);
    }

    exportToSpreadsheet({
      data: sheet,
      fileName: "gooroo-emprestimos-" + dayjs().format("YYYY-MM-DD-HH-mm-ss")
    });
  }

  async getAndOpenContractDownloadUrl(loan: Loan) {
    const [showDocumentError, showDocument] =
      await this.eletronicSignatureService.showDocument(
        loan.clicksignDocumentKey!
      );

    if (showDocumentError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a CCB."
      });
    }

    if (showDocument?.download_url) {
      window.open(showDocument?.download_url, "_blank");
    }
  }

  async openSignatureUrl(loan: Loan) {
    const clickSignUrl = process.env.VUE_APP_CLICKSIGN_BASE_URL;
    const signatureKey = loan.clicksignSignatureKey!;

    if (signatureKey) {
      window.open(`${clickSignUrl}/sign/${signatureKey}`, "_blank");
    }
  }

  closeContinueLoan(): void {
    this.continueLoanCpf = "";
    this.applyFilter();
  }

  loanFinished(): void {
    this.closeContinueLoan();
    this.applyFilter();
  }

  getObservationOfCurrentLoanStatus(item: Loan): string {
    const historyOfThisStatus = item.statusHistory.filter(
      (statusHistory) => statusHistory.statusId === item.statusId
    );

    const sortedHistoryOfThisStatus = orderBy(
      historyOfThisStatus,
      "id",
      "desc"
    );

    return sortedHistoryOfThisStatus?.[0]?.observation || "-";
  }

  showResendBankSlipButton(loan) {
    return (
      loan.status.id === LoanStatusEnum.BANKSLIP_PAYMENT_REFUSED.id &&
      [
        LoanTypeEnum.REFINANCING_OF_IN_PROGRESS_LOANS.id,
        LoanTypeEnum.DEBT_PURCHASE.id
      ].includes(loan.typeId) &&
      this.hasPermissions(["REENVIAR_COMPRA_DIVIDA_REFIN"])
    );
  }

  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }
}
